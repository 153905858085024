var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-container",
    {
      staticStyle: {
        background: "#fff",
        height: "auto",
        overflow: "overlay",
        "flex-direction": "column",
      },
    },
    [
      _c(
        "el-row",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticStyle: {
            padding: "20px",
            "flex-grow": "1",
            overflow: "hidden overlay",
          },
        },
        [
          _c("el-col", { staticClass: "title-bar", attrs: { span: 24 } }, [
            _vm._v("药物基本信息"),
          ]),
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                "status-icon": "",
                model: _vm.drugGroup,
                "label-width": "120px",
              },
            },
            [
              _c(
                "el-form-item",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.drugGroup.id,
                      expression: "drugGroup.id",
                    },
                  ],
                  attrs: { label: "药物组ID:" },
                },
                [
                  _c("el-input", {
                    staticStyle: { width: "300px", float: "left" },
                    attrs: { disabled: true },
                    model: {
                      value: _vm.drugGroup.id,
                      callback: function ($$v) {
                        _vm.$set(_vm.drugGroup, "id", $$v)
                      },
                      expression: "drugGroup.id",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "药物组名称",
                    prop: "name",
                    rules: [
                      {
                        required: true,
                        message: "请输入药物组名称",
                        trigger: "blur",
                      },
                      {
                        validator: _vm.validateDrugGroupeName,
                        trigger: "blur",
                      },
                    ],
                  },
                },
                [
                  _c("el-input", {
                    staticStyle: { width: "300px", float: "left" },
                    attrs: { placeholder: "请输入药物组名称", clearable: "" },
                    model: {
                      value: _vm.drugGroup.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.drugGroup, "name", $$v)
                      },
                      expression: "drugGroup.name",
                    },
                  }),
                ],
                1
              ),
              _c("el-col", { staticClass: "title-bar", attrs: { span: 24 } }, [
                _vm._v("药物组管理"),
              ]),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 22 } },
                    [
                      _c(
                        "el-form",
                        {
                          ref: "drugComponentForm",
                          attrs: {
                            "status-icon": "",
                            model: _vm.drugComponent,
                            "label-width": "120px",
                          },
                        },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "包含药物列表",
                                prop: "drugComponentQueryStr",
                                rules: [
                                  {
                                    required: true,
                                    message: "请输入查询内容",
                                    trigger: "blur",
                                  },
                                  {
                                    min: 1,
                                    max: 30,
                                    message: "查询内容长度在 1 到 30 个字符",
                                    trigger: "blur",
                                  },
                                ],
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    filterable: "",
                                    remote: "",
                                    "popper-class": "select_component",
                                    "remote-method": _vm.remoteMethod_drug,
                                    "reserve-keyword": "",
                                    placeholder: "请输入药物名称",
                                  },
                                  on: {
                                    change: function (val) {
                                      return _vm.changeRemote_drug(val)
                                    },
                                  },
                                  model: {
                                    value:
                                      _vm.drugComponent.drugComponentQueryStr,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.drugComponent,
                                        "drugComponentQueryStr",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "drugComponent.drugComponentQueryStr",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "clearfix table_Header" },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            width: "100px",
                                            flex: "1",
                                          },
                                        },
                                        [_vm._v("药物Id")]
                                      ),
                                      _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            width: "200px",
                                            flex: "2",
                                          },
                                        },
                                        [_vm._v("药物名称")]
                                      ),
                                      _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            width: "100px",
                                            flex: "1",
                                          },
                                        },
                                        [_vm._v("添加")]
                                      ),
                                    ]
                                  ),
                                  _vm._l(
                                    _vm.drugComponentTableData,
                                    function (item, index) {
                                      return _c(
                                        "el-option",
                                        {
                                          key: item.id,
                                          attrs: {
                                            label: item.name,
                                            value: item.id,
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "clearfix spanWrap",
                                            },
                                            [
                                              _c(
                                                "p",
                                                {
                                                  staticStyle: {
                                                    width: "100px",
                                                    flex: "1",
                                                  },
                                                  attrs: { title: item.id },
                                                },
                                                [_vm._v(_vm._s(item.id))]
                                              ),
                                              _c(
                                                "p",
                                                {
                                                  staticStyle: {
                                                    width: "200px",
                                                    flex: "2",
                                                  },
                                                  attrs: { title: item.name },
                                                },
                                                [_vm._v(_vm._s(item.name))]
                                              ),
                                              _c(
                                                "p",
                                                {
                                                  staticStyle: {
                                                    width: "100px",
                                                    flex: "1",
                                                    color: "#2B60F8",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.adddrugGroup(
                                                        index,
                                                        item
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v("添加")]
                                              ),
                                            ]
                                          ),
                                        ]
                                      )
                                    }
                                  ),
                                ],
                                2
                              ),
                              _c(
                                "el-button",
                                {
                                  staticClass: "addClass",
                                  staticStyle: { "margin-left": "20px" },
                                  attrs: {
                                    icon: "el-icon-circle-plus-outline",
                                  },
                                  on: { click: _vm.addDrug },
                                },
                                [_vm._v(" 新增药物 ")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                { staticStyle: { padding: "0px 0px 15px 15px" } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 18 } },
                    [
                      _c(
                        "el-table",
                        {
                          ref: "rno",
                          attrs: {
                            data: _vm.resultNameOptions,
                            height: 400,
                            border: "",
                            stripe: "",
                            "header-cell-style": { "text-align": "center" },
                            "cell-style": { "text-align": "center" },
                          },
                        },
                        [
                          _c("el-table-column", {
                            attrs: {
                              property: "drugId",
                              label: "药物ID",
                              width: "150",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: { property: "drugName", label: "药物名称" },
                          }),
                          _c("el-table-column", {
                            key: "status",
                            attrs: {
                              label: "主要药物",
                              align: "center",
                              width: "100",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c("el-switch", {
                                      staticClass: "switch",
                                      attrs: {
                                        "active-color": "#5DB730",
                                        "active-text": "是",
                                        "inactive-text": "否",
                                        "inactive-color": "#B5CCF5",
                                        "active-value": "1",
                                        "inactive-value": "0",
                                      },
                                      on: {
                                        change: function ($event) {
                                          return _vm.handlePrimaryChange(
                                            scope.row,
                                            scope.index
                                          )
                                        },
                                      },
                                      model: {
                                        value: scope.row.isPrimary,
                                        callback: function ($$v) {
                                          _vm.$set(scope.row, "isPrimary", $$v)
                                        },
                                        expression: "scope.row.isPrimary",
                                      },
                                    }),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c("el-table-column", {
                            attrs: { label: "操作", width: "150" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c(
                                      "el-button",
                                      {
                                        staticStyle: { color: "#FF3333" },
                                        attrs: { type: "text", size: "small" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.handleClick(
                                              scope.$index,
                                              scope.row
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v("删除")]
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c("template", { slot: "empty" }, [
                            _c("img", {
                              attrs: {
                                src: require("@/assets/images/no-booking.svg"),
                                alt: "",
                              },
                            }),
                            _c("p", [_vm._v("暂无数据")]),
                          ]),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-row",
        { staticStyle: { "margin-bottom": "10px" } },
        [
          _c(
            "el-col",
            {
              staticClass: "bottom-bar",
              staticStyle: {
                height: "80px",
                "padding-right": "0px",
                position: "fixed",
                right: "30px",
                bottom: "10px",
              },
              attrs: { span: 20 },
            },
            [
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.drugGroup.id,
                      expression: "drugGroup.id",
                    },
                  ],
                  staticStyle: { float: "right", "margin-left": "10px" },
                  on: { click: _vm.editNext },
                },
                [_vm._v("下一个")]
              ),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.drugGroup.id,
                      expression: "drugGroup.id",
                    },
                  ],
                  staticStyle: { float: "right", "margin-left": "10px" },
                  on: { click: _vm.editPrev },
                },
                [_vm._v("上一个")]
              ),
              _c(
                "el-button",
                {
                  staticStyle: { float: "right", "margin-left": "10px" },
                  on: { click: _vm.returnPage },
                },
                [_vm._v("返回")]
              ),
              _c(
                "el-button",
                {
                  staticStyle: { float: "right", display: "none" },
                  on: { click: _vm.resetForm },
                },
                [_vm._v("重置")]
              ),
              _c(
                "el-button",
                {
                  staticStyle: { float: "right" },
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.submitForm(1)
                    },
                  },
                },
                [_vm._v("保存")]
              ),
              _c(
                "el-button",
                {
                  staticStyle: { float: "right" },
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.submitForm(2)
                    },
                  },
                },
                [_vm._v("保存并关闭")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "药物检索",
            visible: _vm.drugComponent.drugComponentTableVisible,
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(
                _vm.drugComponent,
                "drugComponentTableVisible",
                $event
              )
            },
          },
        },
        [
          _c(
            "el-table",
            {
              attrs: {
                border: "",
                stripe: "",
                "header-cell-style": { "text-align": "center" },
                "cell-style": { "text-align": "center" },
                data: _vm.drugComponent.drugComponentTableData,
                height: 300,
              },
            },
            [
              _c("el-table-column", {
                attrs: { property: "id", label: "药物ID", width: "150" },
              }),
              _c("el-table-column", {
                attrs: { property: "name", label: "药物名称" },
              }),
              _c("el-table-column", {
                attrs: { label: "操作", width: "200" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: {
                              size: "mini",
                              type: "primary",
                              icon: "el-icon-success",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.choosedrugComponentData(
                                  scope.$index,
                                  scope.row
                                )
                              },
                            },
                          },
                          [_vm._v("选择 ")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("template", { slot: "empty" }, [
                _c("img", {
                  attrs: {
                    src: require("@/assets/images/no-booking.svg"),
                    alt: "",
                  },
                }),
                _c("p", [_vm._v("暂无数据")]),
              ]),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }