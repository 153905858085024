<template>
  <el-container style="background:#fff;height: auto;overflow: overlay;flex-direction: column;">
    <el-row style="padding: 20px;flex-grow: 1;overflow: hidden overlay;" v-loading="loading">

      <el-col :span="24" class="title-bar">药物基本信息</el-col>
      <el-form ref="form" status-icon :model="drugGroup" label-width="120px">

        <el-form-item label="药物组ID:"  v-show="drugGroup.id">
          <el-input v-model="drugGroup.id" :disabled="true" style="width: 300px;float: left"/>
        </el-form-item>
        <el-form-item
          label="药物组名称"
          prop="name"
          :rules="[{ required: true, message: '请输入药物组名称', trigger: 'blur'},
           { validator: validateDrugGroupeName, trigger: 'blur'}]">
          <el-input v-model="drugGroup.name" placeholder="请输入药物组名称" style="width: 300px;float: left" clearable></el-input>
        </el-form-item>
        <el-col :span="24" class="title-bar">药物组管理</el-col>
        <el-row>
          <el-col :span="22">
            <el-form ref="drugComponentForm" status-icon :model="drugComponent" label-width="120px">
              <el-form-item label="包含药物列表" prop="drugComponentQueryStr"
                            :rules="[{ required: true, message: '请输入查询内容', trigger: 'blur'},{ min: 1, max: 30, message: '查询内容长度在 1 到 30 个字符', trigger: 'blur' }]"
              >
                <el-select
                  v-model="drugComponent.drugComponentQueryStr"
                  filterable
                  remote
                  popper-class="select_component"
                  :remote-method="remoteMethod_drug"
                  reserve-keyword
                  placeholder="请输入药物名称"
                  @change="(val)=>changeRemote_drug(val)">
                  <div class="clearfix table_Header">
                    <span style="width:100px;flex:1;">药物Id</span>
                    <span style="width:200px;flex:2;">药物名称</span>
                    <span style="width:100px;flex:1;">添加</span>
                  </div>
                  <el-option
                    v-for="(item, index) in drugComponentTableData"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id">
                    <div class="clearfix spanWrap">
                      <p :title="item.id"
                         style="width:100px;flex:1;">{{ item.id }}</p>
                      <p :title="item.name"
                         style="width:200px;flex:2;">{{ item.name }}</p>
                      <p style="width:100px;flex:1;color:#2B60F8;" @click="adddrugGroup(index, item)">添加</p>
                    </div>
                  </el-option>
                </el-select>
                <el-button
                  icon="el-icon-circle-plus-outline"
                  style="margin-left: 20px;"
                  @click="addDrug"
                  class="addClass"
                >
                  新增药物
                </el-button>
              </el-form-item>
            </el-form>
          </el-col>
        </el-row>
        <el-row style="padding: 0px 0px 15px 15px;">
          <el-col :span="18">
            <el-table
              :data="resultNameOptions"
              :height="400"
              border
              stripe
              :header-cell-style="{'text-align':'center'}"
              :cell-style="{'text-align':'center'}"
              ref="rno">
              <el-table-column property="drugId" label="药物ID" width="150"></el-table-column>
              <el-table-column property="drugName" label="药物名称"></el-table-column>
              <el-table-column label="主要药物" align="center" key="status" width="100">
                <template slot-scope="scope">
                  <el-switch
                    class="switch"
                    active-color="#5DB730"
                    active-text='是'
                    inactive-text='否'
                    inactive-color="#B5CCF5"
                    v-model="scope.row.isPrimary"
                    active-value="1"
                    inactive-value="0"
                    @change="handlePrimaryChange(scope.row, scope.index)"
                  ></el-switch>
                </template>
              </el-table-column>
              <el-table-column label="操作" width="150">
                <template slot-scope="scope">
                  <el-button @click="handleClick(scope.$index,scope.row)" type="text" size="small" style="color: #FF3333;">删除</el-button>
                </template>
              </el-table-column>
              <template slot="empty">
                <img src="@/assets/images/no-booking.svg" alt="">
                <p>暂无数据</p>
              </template>
            </el-table>
          </el-col>
        </el-row>
      </el-form>
    </el-row>
    <el-row style="margin-bottom: 10px;">
      <el-col :span="20" class="bottom-bar" style="height: 80px;padding-right: 0px;position: fixed;right: 30px;bottom: 10px;">
        <el-button style="float: right;margin-left: 10px;" @click="editNext" v-show="drugGroup.id">下一个</el-button>
        <el-button style="float: right;margin-left: 10px;" @click="editPrev" v-show="drugGroup.id">上一个</el-button>
        <el-button style="float: right;margin-left: 10px;" @click="returnPage">返回</el-button>
        <el-button style="float: right;display: none" @click="resetForm">重置</el-button>
        <el-button type="primary" style="float: right" @click="submitForm(1)">保存</el-button>
        <el-button type="primary" style="float: right" @click="submitForm(2)">保存并关闭</el-button>
      </el-col>
    </el-row>
    <el-dialog title="药物检索" :visible.sync="drugComponent.drugComponentTableVisible">
      <el-table
        border
        stripe
        :header-cell-style="{'text-align':'center'}"
        :cell-style="{'text-align':'center'}"
        :data="drugComponent.drugComponentTableData"
        :height="300">
        <el-table-column property="id" label="药物ID" width="150"></el-table-column>
        <el-table-column property="name" label="药物名称"></el-table-column>
        <el-table-column label="操作" width="200">
          <template slot-scope="scope">
            <el-button
              size="mini" type="primary" icon="el-icon-success"
              @click="choosedrugComponentData(scope.$index, scope.row)"
            >选择
            </el-button>
          </template>
        </el-table-column>
        <template slot="empty">
          <img src="@/assets/images/no-booking.svg" alt="">
          <p>暂无数据</p>
        </template>
      </el-table>
    </el-dialog>
  </el-container>

</template>
<script>
import { selectDrugNameList, saveOrUpdate, info,list, checkFields } from '@/api/dkm/drugGroup'
import { getConcept } from '@/utils/concept'
import conceptConst from '@/const/conceptConst'
// import {DEEP_CLONE} from "../../../utils/constants";
import { isEmpty } from '@/utils/common'
import { setCache, getCache } from '@/utils/cacheUtil'
import AcrossUtil from '@/utils/acrossUtil'
export default {
  name: 'drugEdit',
  data() {
    return {
      conceptConst,
      drugGroupName: '',
      drugComponentTableData: [],
      injuryAndPoisonCode: [],
      resultNameOptions: [], // 已选择药物成分list
      loading: false,
      drugGroup: {
        name: '', // 中西草标识Id
        id: null, // 药物id
        dkbDrugGroupMappingList: [], // 关联的药物成分
      },
      drugComponent: {
        /*药物成分检索输入框数据*/
        drugComponentQueryStr: null,
        /*药物成分检索表格数据*/
        drugComponentTableData: [],
        /*药物成分检索表格弹是否显示*/
        drugComponentTableVisible: false
      },
      /*药物类型下拉列表*/
      substanceTypeOptions: [],
      /*中西药标识符下拉列表 */
      cwMedicineIdentification: [],
      /**药物成分类型下拉列表 */
      drugComponentTypeOptions: [],
      /*语言概念下拉列表*/
      languageConcep: [],
      /*药物属性名称概念字典*/
      substanceProps: [],
      /*药物属性值概念字典*/
      substancePropsVal: {},
      /*记录每个属性名称选中项，用于控制关联的属性值下拉列表的值*/
      propsNameValSelectOpts: {},
      /*列表页面查询参数*/
      queryParams:{},
      /*存储根据查询获取到的数据，用于翻页*/
      pageData:[]

    }
  },
  mounted: function() {

  },
  created() {
    this.loading=true

    const id = this.$route.params && this.$route.params.id
    if (id) {
      this.queryParams=getCache("drugGroupQueryParams")
      this.pageData=getCache("drugGroupPageData")
      if(!this.queryParams){
        this.queryParams={}
      }
      if(!this.queryParams.pageNum){
        this.queryParams.pageNum=1
      }
      if(!this.queryParams.pageSize){
        this.queryParams.pageSize=10
      }
      this.init().then(result => {
        this.getDrugInfo(id)
      })

    } else {
      this.init().then(result => {
        this.loading=false
      })
    }
  },
  computed: {
    /*计算属性--获取cas编码和unii编码*/
    substanceCode() {
      let code = {}
      this.drugGroup.dkbSubstanceCode.some((item, i) => {
        if (item.codeSysName === 'unii') {
          code.unii = item.substanceCode
        }
        if (item.codeSysName === 'cas') {
          code.cas = item.substanceCode
        }
        if (code.unii && code.cas) {
          return true
        }

      })
      return code
    }
  },
  methods: {
    /*修改药物组里药物是否为默认药物*/
    handlePrimaryChange(row, index) {
      // console.log(row)
      let that = this
      let text = row.isPrimary === '0' ? '取消' : '默认'
      this.$confirm('确认要"' + text + '"ID为"' + row.drugId + '"的药物吗?', '警告', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function() {
        if (row.isPrimary === '1') {
          // console.log(that.resultNameOptions)
          that.resultNameOptions.forEach((item) => {
            item.isPrimary = '0'
          })
          that.resultNameOptions[index].isPrimary = '1'
          // console.log(that.resultNameOptions)
        }
      }).then(() => {
        this.msgSuccess(text + '成功')
      }).catch(function() {
        row.isPrimary = row.isPrimary === '0' ? '1' : '0'
      })
    },
    handleClick(index, row) {
      // console.log(index, row)
      this.resultNameOptions.splice(index, 1)
    },
    flitterData(arr){
      let spanOneArr = [],
      concatOne = 0;
      arr.forEach((item,index)=>{
        if(index === 0){
          spanOneArr.push(1);
        }else{
          if(item.unii === arr[index - 1].unii){ //第一列需合并相同内容的判断条件
            spanOneArr[concatOne] += 1;
            spanOneArr.push(0);
          }else{
            spanOneArr.push(1);
            concatOne = index
          }
        }
      });
      return  {
        one: spanOneArr
      }
    },
    /*初始化--加载字典等*/
    async init() {
      //获取中西药物标识数据字典
      await getConcept(conceptConst.DKB_DRUG_CLASSIFY, 0).then(result => {
        // this.substanceTypeOptions = result
        this.cwMedicineIdentification = result
      })
      /*获取语言概念字典*/
      await getConcept(conceptConst.PUB_LANG, 0).then(result => {
        this.languageConcep = result
      })
      /*获取药物属性概念字典*/
      await getConcept(conceptConst.DKB_SUBSTANCE_PROPS, 1).then(result => {
        this.substanceProps = result
        let propsVal = this.substancePropsVal
        result.forEach(function(item, index) {
          propsVal[item.conceptId] = item.sonDictTermConcept
        })
      })
      /*获取药物成分类型字典*/
      await getConcept(conceptConst.DKB_DRUG_INGREDIENT_TYPE, 0).then(result => {
        this.drugComponentTypeOptions = result
      })
      // console.log(drugGroupList)
      if (this.$route.query.drugGroupLis !== undefined) {
        let drugGroupList = JSON.parse(this.$route.query.drugGroupList)
        // console.log(drugGroupList)
        let drugComponentArr = []
        drugGroupList.forEach((item) => {
          let drugComponentObj = {}
          drugComponentObj.drugId = item.id
          drugComponentObj.drugName = item.name
          drugComponentArr.push(drugComponentObj)
        })
        this.resultNameOptions = drugComponentArr
      }
    },

    /** 保存按钮--新增药物 */
    submitForm(type) {
      this.$refs['form'].validate(valid => {
        if (valid) {
          if (this.resultNameOptions.length === 0) {
            this.msgError('至少选择一条药物成分')
          } else {
            this.drugGroup.dkbDrugGroupMappingList = this.resultNameOptions
            saveOrUpdate(this.drugGroup).then(response => {
              this.msgSuccess(response.msg)
              //调用刷新表格数据的方法
              AcrossUtil.$emit('tableReload');
              if(type==2){
                //this.returnPage()
                this.$store.dispatch('tagsView/delView', this.$route)
                this.$router.push('/dkm/drugGroup')
              }else{
                if(response.data){
                  //保存完成后根据id跳转到编辑界面
                  this.$store.dispatch('tagsView/delView', this.$route).then(res=>{
                    this.$nextTick(() => {
                      this.$router.replace({
                        path: '/redirect' + '/drugGroup/edit/' + response.data
                      })
                    })
                  })
                }
              }
            })
          }
        } else {
          this.msgError('请规范填写')
        }
      })
    },

    /*返回药物列表页面*/
    returnPage() {
      const id = this.$route.params && this.$route.params.id
      if (id) {
        this.init().then(result => {
          this.getDrugInfo(id)
        })
      } else {
        this.init()
        this.clearData()
      }
      this.$store.dispatch('tagsView/delView', this.$route)
      this.$store.dispatch('tagsView/delCachedView', {name: "drugGroup"}).then(res => {
        this.$router.push('/dkm/drugGroup')
      })
    },
    clearData() {
      this.$nextTick(() => {
        if (this.$refs.form !== undefined) {
          this.$refs.form.resetFields()
        }
        if (this.$refs.drugComponentForm !== undefined) {
          this.$refs.drugComponentForm.resetFields()
        }
        this.drugGroup = {
          name: '', // 中西草标识Id
          id: null, // 药物id
          dkbDrugGroupMappingList: [], // 关联的药物成分
        }
        this.resultNameOptions = []
      })
    },
    /*根据药物id获取药物明细*/
    getDrugInfo(id) {
      let that = this
      info(id).then(response => {
        this.drugGroup.id = response.data.id
        this.drugGroup.name = response.data.name
        this.drugGroup.dkbDrugGroupMappingList = response.data.dkbDrugGroupMappingList
        this.resultNameOptions = this.drugGroup.dkbDrugGroupMappingList
        this.loading=false
      })
    },
    remoteMethod_drug(val) {
      // console.log(val)
      if (val && val.trim() !== '') {
        let obj = {}
        obj.queryStr = val
        selectDrugNameList(obj).then(response => {
          this.drugComponentTableData = response.data
        })
      }
    },
    changeRemote_drug(val) {
      // console.log(val)
    },
    adddrugGroup(index, row) {
      const item = this.resultNameOptions.find(item => item.drugId === row.id)
      // console.log(index,row)
      // console.log(item)
      // console.log(this.resultNameOptions)
      if (item) {
        this.msgError('药物不能重复选择，请重新添加！')
        return false
      } else {
        // console.log(index,row)
        let drugComponentObj = {}
        drugComponentObj.drugId = row.id
        drugComponentObj.drugName = row.name
        drugComponentObj.isPrimary = '0'
        this.resultNameOptions.push(drugComponentObj)
        // console.log(this.resultNameOptions)
        this.$nextTick(() => {
          this.$refs.rno.doLayout()
        })
      }
    },
    /*药物成分检索*/
    queryDrugComponent() {
      this.$refs['drugComponentForm'].validate(valid => {
        if (valid) {
          let obj = {}
          obj.queryStr = this.drugComponent.drugComponentQueryStr
          this.drugComponent.drugComponentTableVisible = true
          selectDrugNameList(obj).then(response => {
            // console.log(response)
            this.drugComponent.drugComponentTableData = response.data
            // console.log(response.data)
            this.loading = false
          })
        } else {
        }
      })
    },
     /*药品成分检索表格，选中数据*/
    choosedrugComponentData(index, row) {
      const item = this.resultNameOptions.find(item => item.substanceId === row.substanceId)
      // console.log(index,row)
      if (item) {
        this.msgError('药物不能重复选择，请重新添加！')
        return false
      } else {
        // console.log(index,row)
        let drugComponentObj = {}
        drugComponentObj.drugId = row.id
        drugComponentObj.drugName = row.name
        this.resultNameOptions.push(drugComponentObj)
        // console.log(this.resultNameOptions)
        this.$nextTick(() => {
          this.$refs.rno.doLayout()
        })
      }
    },
    /*重置表单*/
    resetForm(formName) {
      let form = this.$refs[formName]
      if (form) {
        form.resetFields()
      }
    },
    // 新增药物
    addDrug() {
      this.$router.push('/drug/edit/')
    },
    /* 药物组名称-重复性校验*/
    validateDrugGroupeName(rule, value, callback){
      if(!isEmpty(value)){
        // 调用接口校验
        let obj = {}
        obj.fieldName = 'name'
        obj.fieldValue = this.drugGroup.name
        obj.drugGroupId = this.drugGroup.id
        checkFields(obj).then(response => {
          // console.log(response)
          // console.log(response.data &&  response.data.length>0)
          let item=response.data && response.data !== null ? response.data:undefined
          // console.log(item)
          if(item){
            callback(new Error('药物组名称已存在,药物组id为['+item.id+']'));
          }else{
            callback();
          }
        })
      }else{
        callback();
      }
    },
    async editNext(){
      if(this.pageData==undefined){
        //刷新页面直接进入edit页面，点击下一页上一页跳转到列表页面
        this.$store.dispatch('tagsView/delView', this.$route).then(res=>{
          this.$router.push('/dkm/drugGroup')
          return
        })
      }
      let nowIndex=-1
      let id=this.drugGroup.id
      this.pageData.some((item,index)=>{
        if(item.id==id){
          nowIndex=index
          return true
        }
      })
      if(nowIndex>-1 && nowIndex< this.pageData.length-1){
        id=this.pageData[nowIndex+1].id
      }else{
        //查询下一页数据
        this.queryParams.pageNum++
        //向上取整
        let maxPage=Math.ceil(this.queryParams.total/this.queryParams.pageSize)
        if (this.queryParams.pageNum>maxPage){
          this.msgInfo('已经是最后一条记录了')
          this.queryParams.pageNum--
          return;
        }
        let response=await list(this.queryParams)
        this.queryParams.total=response.total
        setCache("drugGroupQueryParams",this.queryParams)
        setCache("drugGroupPageData",response.rows)
        this.pageData= response.rows
        id=this.pageData[0].id
      }
      this.$store.dispatch('tagsView/delView', this.$route).then(res=>{
        this.$router.push('/drugGroup/edit/' + id)
      })
    },
    async editPrev(){
      if(this.pageData==undefined){
        //刷新页面直接进入edit页面，点击下一页上一页跳转到列表页面
        this.$store.dispatch('tagsView/delView', this.$route).then(res=>{
          this.$router.push('/dkm/drugGroup')
          return
        })
      }
      let nowIndex=-1
      let id=this.drugGroup.id
      this.pageData.some((item,index)=>{
        if(item.id==id){
          nowIndex=index
          return true
        }
      })
      if(nowIndex>0 ){
        id=this.pageData[nowIndex-1].id
      }else{
        //查询上一页数据
        this.queryParams.pageNum--
        if(this.queryParams.pageNum<1){
          this.msgInfo('已经是第一条记录了')
          this.queryParams.pageNum++
          return;
        }
        let response=await list(this.queryParams)
        this.queryParams.total=response.total
        setCache("drugGroupQueryParams",this.queryParams)
        setCache("drugGroupPageData",response.rows)
        this.pageData= response.rows
        id=this.pageData[response.rows.length-1].id
      }
      this.$store.dispatch('tagsView/delView', this.$route).then(res=>{
        this.$router.push('/drugGroup/edit/' + id)
      })
    },

  }
}
</script>


<style lang="scss" scoped>
/*分类标题*/
.title-bar {
  border-left: 5px solid #0073E9;
  padding-left: 10px;
  border-radius: 2px;
  margin-bottom: 20px;
}

/*删除按钮*/
.el-icon-remove {
  color: #F52C2C;
  font-size: 20px;
  padding: 0px 6px;
}

/*药物编码div*/
#substanceCodeDiv {
  width: 100%;
  /*height: 155px;*/
  background: #EBF0FA;
  border: 1px solid #BDD3FF;
  border-radius: 4px;
  padding: 10px 20px;
  float:left;
  position: relative;
}

#substanceCodeDiv p {
  height: 14px;
  line-height: 14px;
  padding:5px 0px;
}

#substanceCodeDiv label {
  display:block;
  float:left;
  width:80px;
  text-align:right;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 600;
  color: #666666;
  margin-left: 20px;
}

#substanceCodeDiv span {
  width:700px;
  display:block;
  float:left;
  padding-left:5px;
  font-size: 14px;
  line-height:18px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #333333;
}

.bottom-bar {
  width: 100%;
  height: 30px;
  position: fixed;
  bottom: 60px;
  z-index: 200;
  font-size: 12px;
  line-height: 30px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #999999;
  text-align: center;
  padding-right: 260px;
}

/*推荐属性*/
#recommendProps button {
  min-width: 100px;
  color: #2B60F8
}

.el-table .cell .el-form-item {
  margin-bottom: 0px;
}
.jgs_pic{
  width: 180px;
  height: 180px;
  background: #EBF0FA;
  border: 1px solid #BDD3FF;
  border-radius: 4px;
  margin-top:58px;
  position: absolute;
  right: 10px;
  top: -50px;
}
.spanWrap p{
  margin: 0!important;
}
.el-form-item{
  margin-bottom: 18px;
}
</style>
